import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';

store.dispatch('checkAuth').then(() => {
    createApp(App).use(store).use(router).component('EasyDataTable', Vue3EasyDataTable).mount('#app')
})

