<template>
  <section class="hero is-fullheight has-background">
    <div class="hero-body">
      <div class="container has-text-centered rounded-container">
        <div class="column is-4 is-offset-4">
          <div class="box">
            <figure class="avatar">
              <img
                src="../../assets/logo.png"
                style="width: auto; height: 150px"
              />
            </figure>
            <h3 class="title has-text-black">Portal</h3>
            <h3 class="subtitle has-text-black">Login</h3>

            <div class="field">
              <div class="control">
                <input
                  class="input is-normal is-rounded"
                  type="email"
                  placeholder="Email"
                  v-model="email"
                  autofocus=""
                />
              </div>
            </div>

            <div class="field">
              <div class="control has-icons-right position-relative">
                <input
                  :type="showPassword ? 'text' : 'password'"
                  class="input is-normal is-rounded"
                  placeholder="Password"
                  v-model="password"
                />
                <i
                  class="fas password-icon"
                  :class="showPassword ? 'fa-eye-slash' : 'fa-eye'"
                  @click.stop="showPassword = !showPassword"
                ></i>
              </div>
            </div>

            <div
              v-if="error"
              class="field has-text-centered is-size-7 has-text-danger"
            >
              {{ error }}
            </div>

            <button
              class="button is-block is-info is-normal is-fullwidth is-rounded mt-6 mb-3"
              :class="{ 'is-loading': isPending }"
              @click="login"
            >
              Login
            </button>

            <div
              class="container is-flex is-align-items-center is-justify-content-space-between"
            >
              <h3 class="is-size-7 has-text-black" style="margin-top: 0">
                {{ versionNumber }}
              </h3>
              <router-link to="/forgot-password" class="is-pulled-right"
                >Forgot Password?</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
export default {
  setup() {
    const email = ref("");
    const password = ref("");
    const showPassword = ref(false);
    const store = useStore();
    const isPending = ref(false);
    const error = ref(null);
    const router = useRouter();

    const login = async () => {
      try {
        isPending.value = true;
        await store.dispatch("login", {
          email: email.value,
          password: password.value,
        });
        // window.location.reload();
        router.push("/dashboard");
      } catch (err) {
        isPending.value = false;
        error.value = err.message;
        //console.log(err.message);
      }
    };

    return {
      email,
      password,
      login,
      showPassword,
      isPending,
      error,
      versionNumber: computed(() => store.state.versionNumber),
    };
  },
};
</script>

<style>
</style>