<template>
  <div class="modal" :class="{ 'is-active': showForm }">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <div class="is-flex is-justify-content-space-between">
          <p class="modal-card-title">Add New Customer</p>
        </div>
      </header>

      <section class="modal-card-body">
        <form @submit.prevent>
          <div class="field">
            <label class="label">First Name</label>
            <div class="control">
              <input
                type="text"
                class="input"
                required
                v-model="customerInfo.firstName"
              />
            </div>
          </div>

          <div class="field">
            <label class="label">Last Name</label>
            <div class="control">
              <input
                type="text"
                class="input"
                required
                v-model="customerInfo.lastName"
              />
            </div>
          </div>

          <div class="field">
            <label class="label">Gender</label>
            <div class="control">
              <div class="select">
                <select required v-model="customerInfo.gender">
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                </select>
              </div>
            </div>
          </div>

          <div class="field">
            <label class="label">Mobile</label>
            <div class="control" required>
              <input
                type="number"
                class="input"
                v-model="customerInfo.mobileNumber"
              />
            </div>
          </div>

          <div class="field">
            <label class="label">Landline </label>
            <div class="control">
              <input
                type="number"
                class="input"
                v-model="customerInfo.landlineNumber"
              />
            </div>
          </div>

          <div class="field">
            <label class="label">Email Address</label>
            <div class="control">
              <input
                type="email"
                class="input"
                required
                v-model="customerInfo.emailAddress"
              />
            </div>
          </div>

          <div class="field">
            <label class="label">Address Line 1</label>
            <div class="control">
              <textarea
                class="textarea"
                required
                v-model="customerInfo.addressLine1"
              ></textarea>
            </div>
          </div>

          <div class="field">
            <label class="label">Address Line 2</label>
            <div class="control">
              <textarea
                class="textarea"
                v-model="customerInfo.addressLine2"
              ></textarea>
            </div>
          </div>

          <div class="field">
            <label class="label">DOB</label>
            <div class="control">
              <input
                type="date"
                class="input"
                v-model="customerInfo.dateOfBirth"
              />
            </div>
          </div>

          <div class="field">
            <label class="label">Notes</label>
            <div class="control">
              <textarea
                class="textarea"
                v-model="customerInfo.notes"
              ></textarea>
            </div>
          </div>

          <div class="field">
            <label class="label">Status</label>
            <div class="control">
              <div class="select">
                <select required v-model="customerInfo.status">
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                </select>
              </div>
            </div>
          </div>

          <div class="field">
            <label class="label">Added By</label>
            <div class="control">
              <input
                type="text"
                class="input"
                required
                v-model="customerInfo.addedBy"
              />
            </div>
          </div>
        </form>
      </section>
      <footer class="modal-card-foot">
        <button
          class="button is-rounded is-success"
          :class="{ 'is-loading': isPending }"
          @click="handleClick"
        >
          Create
        </button>
        <button class="button is-rounded" @click="closeModal">Cancel</button>
      </footer>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import useCollection from "@/composables/useCollection";
import { Timestamp } from "firebase/firestore";

export default {
  props: ["showForm"],

  setup(props, context) {
    const customerInfo = ref({
      firstName: "",
      lastName: "",
      gender: "",
      mobileNumber: "",
      landlineNumber: "",
      emailAddress: "",
      addressLine1: "",
      addressLine2: "",
      dateOfBirth: "",
      notes: "",
      status: "",
      addedBy: "",
    });

    const { addDocument, error, isPending } = useCollection("Customers");

    // Close modal
    const closeModal = () => {
      context.emit("close");
    };

    // Create new Customer
    const handleClick = async () => {
      isPending.value = true;
      try {
        const newCustomer = {
          firstName: customerInfo.value.firstName,
          lastName: customerInfo.value.lastName,
          gender: customerInfo.value.gender,
          mobileNumber: customerInfo.value.mobileNumber,
          landlineNumber: customerInfo.value.landlineNumber,
          emailAddress: customerInfo.value.emailAddress,
          addressLine1: customerInfo.value.addressLine1,
          addressLine2: customerInfo.value.addressLine2,
          dateOfBirth: customerInfo.value.dateOfBirth,
          notes: customerInfo.value.notes,
          status: customerInfo.value.status,
          addedBy: customerInfo.value.addedBy,
          createdAt: Timestamp.now(),
          modifiedAt: Timestamp.now(),
        };

        await addDocument(newCustomer);
        if (!error.value) {
          closeModal();
        }
      } catch (err) {
        console.log(err);
      }
    };

    return {
      closeModal,
      isPending,
      customerInfo,
      handleClick,
    };
  },
};
</script>

<style>
</style>