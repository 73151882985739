<template>
  <div class="comtainer is-flex is-justify-content-space-between">
    <div>
      <h1 class="is-size-3 title">Orders</h1>
      <div class="container is-flex mb-5">
        <div class="field mr-5">
          <label class="label">Search Item</label>
          <div class="select">
            <select v-model="searchField">
              <option value="orderId">Order ID</option>
              <option value="customerName">Customer Name</option>
              <option value="status">Status</option>
            </select>
          </div>
        </div>

        <div v-if="searchField != 'status'" class="field mr-5">
          <label for="" class="label">Search For</label>
          <input type="text" class="input" v-model="searchValue" />
        </div>
        <div v-else class="field mr-5">
          <label for="" class="label">Search Item</label>
          <div class="select">
            <select name="" id="" v-model="searchValue">
              <option value="new">New</option>
              <option value="inProgress">In Progress</option>
              <option value="fitOn">Fit On</option>
              <option value="pendingCollection">Pending Collection</option>
              <option value="pendingDelivery">Pending Delivery</option>
              <option value="completed">Completed</option>
            </select>
          </div>
        </div>

        <div class="field mt-5" v-if="searchValue">
          <div class="control">
            <button class="button is-light is-small" @click="searchValue = ''">
              Clear Filter
            </button>
          </div>
        </div>
      </div>
    </div>

    <div>
      <button
        v-if="!showForm"
        @click="showForm = true"
        class="button is-rounded is-primary"
      >
        New Order
      </button>
    </div>
  </div>

  <div v-if="orders">
    <EasyDataTable
      :headers="headers"
      :items="orders"
      :search-field="searchField"
      :search-value="searchValue"
      v-model:items-selected="selectedOrders"
      header-text-direction="center"
      body-text-direction="center"
      table-class-name="customize-table"
      border-cell
      show-index
    >
      <template #item-customer="{ customer }">
        {{ customer.firstName }} {{ customer.lastName }}
      </template>

      <template #item-dueDate="{ dueDate }">
        {{ dueDate.toDate().toDateString() }}
      </template>

      <template #item-status="{ status }">
        <span class="tag is-primary is-rounded" v-if="status == 'new'">
          NEW</span
        >
        <span class="tag is-info is-rounded" v-if="status == 'inProgress'"
          >IN PROGRESS</span
        >
        <span class="tag is-warning is-rounded" v-if="status == 'fitOn'"
          >FIT ON</span
        >
        <span
          class="tag is-danger is-rounded"
          v-if="status == 'pendingCollection'"
          >PENDING COLLECTION</span
        >
        <span
          class="tag is-danger is-rounded"
          v-if="status == 'pendingDelivery'"
          >PENDING DELIVERY</span
        >
        <span class="tag is-black is-rounded" v-if="status == 'completed'"
          >COMPLETED</span
        >
      </template>

      <template #item-orderType="{ orderType }">
        <span
          class="tag is-rounded is-info is-light"
          v-if="orderType == 'tailoring'"
        >
          TAILORING
        </span>
        <span
          class="tag is-rounded is-warning is-light"
          v-if="orderType == 'alteration'"
        >
          ALTERATION
        </span>
        <span
          class="tag is-rounded is-danger is-light"
          v-if="orderType == 'repair'"
        >
          REPAIR
        </span>
      </template>
    </EasyDataTable>
  </div>
  <div v-else><spinner /></div>

  <!-- Modals -->
  <div v-if="showForm">
    <new-oder-modal :showForm="showForm" @close="showForm = false" />
  </div>
</template>

<script>
import { ref, watchEffect } from "vue";
import { useStore } from "vuex";
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { db } from "@/firebase/config";
import Spinner from "@/components/Spinner.vue";
import NewOderModal from "@/components/modals/NewOderModal.vue";
export default {
  components: { Spinner, NewOderModal },
  setup() {
    const store = useStore();
    store.commit("setCurrentRoute", "Orders");

    const showForm = ref(false);

    const selectedOrders = ref([]);

    const orders = ref(null);
    const searchField = ref("orderId");
    const searchValue = ref("");

    const headers = [
      { text: "Order Id", value: "id", sortable: true },
      {
        text: "Customer Name",
        value: "customer",
        sortable: true,
      },
      {
        text: "Contact Number",
        value: "customer.mobileNumber",
        sortable: true,
      },
      { text: "Due Date", value: "dueDate", sortable: true },
      { text: "Order Type", value: "orderType", sortable: false },
      { text: "Order Items", value: "orderItems", sortable: false },
      { text: "Status", value: "status", sortable: true },
      { text: "Created By", value: "createdBy", sortable: true },
      { text: "Operations", value: "operations", sortable: false },
    ];

    let colRef = collection(db, "Orders");
    colRef = query(colRef, orderBy("dueDate"));

    const unSub = onSnapshot(colRef, (snap) => {
      let results = [];
      snap.docs.forEach((doc) => {
        results.push({ ...doc.data(), id: doc.id });
      });
      orders.value = results;
      console.log(results);
    });

    watchEffect((onInvalidate) => {
      onInvalidate(() => unSub());
    });

    return {
      searchField,
      searchValue,
      orders,
      headers,
      showForm,
      selectedOrders,
    };
  },
};
</script>

<style>
</style>