<template>
  <aside class="menu">
    <!-- Operations Section -->
    <p class="menu-label">Operations</p>
    <ul class="menu-list">
      <li>
        <a
          class="menu-item-color"
          @click="handleRouting('Dashboard')"
          :class="{ 'is-active': currentPath == 'Dashboard' }"
          >Dashboard
        </a>
        <a
          @click="handleRouting('Orders')"
          :class="{ 'is-active': currentPath == 'Orders' }"
          >Orders
        </a>
      </li>
    </ul>

    <!-- CRM Section -->
    <p class="menu-label">CRM</p>
    <ul class="menu-list">
      <li>
        <a
          class="menu-item-color"
          @click="handleRouting('Customers')"
          :class="{ 'is-active': currentPath == 'Customers' }"
          >Customers
        </a>
        <a
          @click="handleRouting('Suppliers')"
          :class="{ 'is-active': currentPath == 'Suppliers' }"
          >Suppliers
        </a>
      </li>
    </ul>

    <!-- Settings Section -->
    <p class="menu-label">Settings</p>
    <ul class="menu-list">
      <li>
        <a
          class="menu-item-color"
          @click="handleRouting('Profile')"
          :class="{ 'is-active': currentPath == 'Profile' }"
          >Profile
        </a>
      </li>
    </ul>

    <!-- Admin Section -->
    <p class="menu-label" v-if="(userProfile.userRole = 'ADMIN')">Admin</p>
    <ul class="menu-list" v-if="(userProfile.userRole = 'ADMIN')">
      <li>
        <a
          class="menu-item-color"
          @click="handleRouting('Users')"
          :class="{ 'is-active': currentPath == 'Users' }"
          >Users
        </a>
        <a
          @click="handleRouting('Finance')"
          :class="{ 'is-active': currentPath == 'Finance' }"
          >Finance
        </a>
      </li>
    </ul>
  </aside>
</template>

<script>
import { computed } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
export default {
  setup() {
    const router = useRouter();
    const store = useStore();
    const currentPath = computed(() => store.state.currentRoute);

    const handleRouting = (route) => {
      router.push({ name: route });
    };

    return {
      handleRouting,
      currentPath,
      userProfile: computed(() => store.state.userProfile),
    };
  },
};
</script>

<style scoped>
.is-active {
  background-color: #000000;
  color: rgb(207, 45, 45);
}
</style>