import { createRouter, createWebHistory } from 'vue-router'
import LoginPage from '@/views/auth/LoginPage.vue'

import Dashboard from '@/views/operations/Dashboard.vue'
import Orders from '@/views/operations/Orders.vue'

import Customers from '@/views/crm/Customers.vue'
import Suppliers from '@/views/crm/Suppliers.vue'

import Profile from '@/views/settings/Profile.vue'

import Users from '@/views/admin/Users.vue'
import Finance from '@/views/admin/Finance.vue'

// Importing the Vuex store
import store from '@/store/index'

// Navigation guard to check if the user is authenticated
const requireAuth = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    next(); // Proceed to the next middleware if authenticated
  } else {
    next('/login'); // Redirect to login if not authenticated
  }
}

// Navigation guard to prevent authenticated users from accessing unauthenticated routes
const requireNoAuth = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    next('/dashboard'); // Redirect to dashboard if already authenticated
  } else {
    next(); // Proceed to the next middleware if not authenticated
  }
}

const routes = [
  // Unauthenticated Routes
  // Login Page
  {
    path: '/login',
    name: 'Login',
    component: LoginPage,
    beforeEnter: requireNoAuth, // Apply navigation guard
  },

  // Authenticated Routes
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    beforeEnter: requireAuth, // Apply navigation guard
  },
  {
    path: '/orders',
    name: 'Orders',
    component: Orders,
    beforeEnter: requireAuth, // Apply navigation guard
  },
  {
    path: '/customers',
    name: 'Customers',
    component: Customers,
    beforeEnter: requireAuth, // Apply navigation guard
  },
  {
    path: '/suppliers',
    name: 'Suppliers',
    component: Suppliers,
    beforeEnter: requireAuth, // Apply navigation guard
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    beforeEnter: requireAuth, // Apply navigation guard
  },
  {
    path: '/users',
    name: 'Users',
    component: Users,
    beforeEnter: requireAuth, // Apply navigation guard
  }, {
    path: '/finance',
    name: 'Finance',
    component: Finance,
    beforeEnter: requireAuth, // Apply navigation guard
  },

  // Redirects
  {
    path: '/',
    redirect: '/login' // Redirect root path to login
  },

  // 404 Error
  // {
  //   path: '/:catchAll(.*)',
  //   name: 'notFound',
  //   component: ErrorPage,
  //   // beforeEnter: requireAuth
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
