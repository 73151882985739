<template>
  <nav
    class="navbar is-fixed-top is-black"
    role="navigation"
    aria-label="main navigation"
  >
    <div class="navbar-brand">
      <router-link class="navbar-item" to="/">
        <img src="../assets/logo.png" alt="logo" />
        <h1 class="ml-2">Portal - Divine Drapers</h1>
      </router-link>

      <!-- Hamburger menu -->
      <a
        role="button"
        class="navbar-burger burger"
        aria-label="menu"
        aria-expanded="false"
        data-target="navbar-menu"
        @click="toggleMenu"
      >
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>
    <div class="navbar-menu" :class="{ 'is-active': menuActive }">
      <div class="navbar-start" v-if="userProfile">
        <!-- <router-link
          to="/dashboard"
          class="navbar-item"
          active-class="is-active"
          >Dashboard</router-link
        >
        <router-link
          to="/accounts"
          class="navbar-item"
          active-class="is-active"
          v-if="userProfile.userRole == 'ADMIN'"
          >Users</router-link
        >
        <router-link to="/profile" class="navbar-item" active-class="is-active"
          >Profile</router-link
        > -->
        <!-- <router-link to="/support" class="navbar-item" active-class="is-active"
          >Support</router-link
        > -->
      </div>

      <div v-if="userProfile" class="navbar-end">
        <div class="navbar-item">
          Curently Logged in as {{ userProfile.firstName }} (<span
            class="is-danger"
            ><b>{{ userProfile.userRole }}</b></span
          >)
        </div>
        <div class="navbar-item">
          <button class="button is-danger is-rounded is-small" @click="logout">
            Logout
          </button>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { computed, ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
export default {
  setup() {
    const router = useRouter();
    const store = useStore();

    const menuActive = ref(false);

    const logout = async () => {
      await store.dispatch("logout");
      router.push({ name: "Login" });
    };

    const toggleMenu = () => {
      menuActive.value = !menuActive.value; // Toggle the menu
    };

    return {
      menuActive,
      toggleMenu,
      logout,
      userProfile: computed(() => store.state.userProfile),
    };
  },
};
</script>

<style>
</style>