import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore, serverTimestamp } from 'firebase/firestore'
import { getStorage } from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyByModpiLn_P-X73Pno2C5bfte0Cjvmb-s",
    authDomain: "divine-drapes.firebaseapp.com",
    projectId: "divine-drapes",
    storageBucket: "divine-drapes.appspot.com",
    messagingSenderId: "879496690320",
    appId: "1:879496690320:web:226781110254cb76b63615",
    measurementId: "G-Q2QTR1K1Y3"
};

// init firebase Backend
initializeApp(firebaseConfig)

// init services
const auth = getAuth()
const db = getFirestore()
const timestamp = serverTimestamp
const storage = getStorage()


export { db, auth, timestamp, storage }