<template>
  <div class="modal" :class="{ 'is-active': showForm }">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <div class="is-flex is-justify-content-space-between">
          <p class="modal-card-title">Create New Order</p>
        </div>
      </header>

      <section class="modal-card-body">
        <form @submit.prevent>
          <div class="field">
            <label class="label">Customer Name</label>
            <div class="control">
              <div class="select">
                <select required v-model="orderInfo.customer">
                  <option value="">Select Customer</option>
                  <option
                    v-for="customer in customers"
                    :key="customer.id"
                    :value="customer"
                  >
                    {{ customer.firstName }} {{ customer.lastName }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div class="field">
            <label class="label">Due Date</label>
            <div class="control">
              <input
                type="date"
                class="input"
                required
                v-model="orderInfo.dueDate"
              />
            </div>
          </div>

          <!-- <div class="field">
            <label class="label">Order Status</label>
            <div class="control">
              <div class="select">
                <select required v-model="orderInfo.status">
                  <option value="">Select Status</option>
                  <option value="new">New</option>
                  <option value="inProgress">In Progress</option>
                  <option value="fitOn">Fit On</option>
                  <option value="pendingCollection">Pending Collection</option>
                  <option value="pendingDelivery">Pending Delivery</option>
                  <option value="completed">Completed</option>
                </select>
              </div>
            </div>
          </div> -->

          <div class="field">
            <label class="label">Order Type</label>
            <div class="control">
              <div class="select">
                <select required v-model="orderInfo.orderType">
                  <option value="">Select Order Type</option>
                  <option value="tailoring">Tailoring</option>
                  <option value="alteration">Alteration</option>
                  <option value="repair">Repair</option>
                </select>
              </div>
            </div>
          </div>

          <div class="field">
            <label class="label">Order Items</label>
            <div class="control">
              <div class="select">
                <select required v-model="orderInfo.orderItems">
                  <option value="">Select Order Items</option>
                  <option value="shirt">Shirt</option>
                  <option value="pants">Pants</option>
                  <option value="suit">Suit</option>
                  <option value="jacket">Jacket</option>
                  <option value="coat">Coat</option>
                  <option value="vest">Vest</option>
                  <option value="dress">Dress</option>
                  <option value="skirt">Skirt</option>
                  <option value="blouse">Blouse</option>
                  <option value="jumper">Jumper</option>
                  <option value="coat">Coat</option>
                  <option value="vest">Vest</option>
                </select>
              </div>
            </div>
          </div>

          <div class="field">
            <label class="label">Notes</label>
            <div class="control">
              <textarea class="textarea" v-model="orderInfo.notes"></textarea>
            </div>
          </div>
        </form>
      </section>

      <footer class="modal-card-foot">
        <button
          class="button is-rounded is-success"
          :class="{ 'is-loading': isPending }"
          @click="handleClick"
        >
          Create
        </button>
        <button class="button is-rounded" @click="closeModal">Cancel</button>
      </footer>
    </div>
  </div>
</template>

<script>
import useCollection from "@/composables/useCollection";
import { computed, ref } from "vue";
import { collection, getDocs, Timestamp } from "firebase/firestore";
import { db } from "@/firebase/config";
import { useStore } from "vuex";
export default {
  props: ["showForm"],
  setup(props, context) {
    const orderInfo = ref({
      customer: "",
      dueDate: "",
      status: "new",
      orderType: "",
      orderItems: "",
      notes: "",
      estimatedCost: "",
    });

    const store = useStore();

    const customers = ref([]);

    const { addDocument, error, isPending } = useCollection("Orders");

    const fetchCustomers = async () => {
      const customersRef = collection(db, "Customers");
      const snapshot = await getDocs(customersRef);
      // add the snapshot data to the customer list
      const documents = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      customers.value = documents;
    };

    const currentUser = computed(() => store.state.userProfile);

    fetchCustomers();

    const handleClick = async () => {
      try {
        await addDocument({
          ...orderInfo.value,
          dueDate: Timestamp.fromDate(new Date(orderInfo.value.dueDate)),
          createdAt: Timestamp.fromDate(new Date()),
          modifiedAt: Timestamp.fromDate(new Date()),
          createdBy: currentUser.value.firstName,
          createdById: currentUser.value.id,
        });
        if (!error.value) {
          closeModal();
        }
      } catch (err) {
        console.log(err.message);
      }
    };

    // Close modal
    const closeModal = () => {
      context.emit("close");
    };

    return {
      orderInfo,
      customers,
      handleClick,
      isPending,
      closeModal,
    };
  },
};
</script>

<style>
</style>