<template>
  <div class="comtainer is-flex is-justify-content-space-between">
    <div>
      <h1 class="is-size-3 title">Customers</h1>
      <div class="container is-flex mb-5">
        <div class="field mr-5">
          <label class="label">Search Item</label>
          <div class="select">
            <select v-model="searchField">
              <option value="firstName">First Name</option>
              <option value="last">Last Name</option>
              <option value="mobileNumber">Mobile Number</option>
              <option value="emailAddress">Email Address</option>
            </select>
          </div>
        </div>

        <div class="field mr-5">
          <label for="" class="label">Search For</label>
          <input type="text" class="input" v-model="searchValue" />
        </div>

        <div class="field mt-5" v-if="searchValue">
          <div class="control">
            <button class="button is-light is-small" @click="searchValue = ''">
              Clear Filter
            </button>
          </div>
        </div>
      </div>
    </div>

    <div>
      <button
        v-if="!showForm"
        @click="showForm = true"
        class="button is-rounded is-primary"
      >
        Add New Customer
      </button>
    </div>
  </div>

  <div v-if="customers">
    <EasyDataTable
      :headers="headers"
      :items="customers"
      :search-field="searchField"
      :search-value="searchValue"
      header-text-direction="center"
      body-text-direction="center"
      table-class-name="customize-table"
      border-cell
      show-index
    >
      <template #item-status="{ status }">
        <span v-if="status == 'active'" class="tag is-rounded is-success">
          ACTIVE
        </span>

        <span v-if="status == 'inactive'" class="tag is-rounded is-dark">
          INACIVE
        </span>
      </template>
    </EasyDataTable>
  </div>
  <div v-else><spinner /></div>

  <!-- Modals -->
  <div v-if="showForm">
    <NewCustomerModal :showForm="showForm" @close="showForm = false" />
  </div>
</template>
  
  <script>
import { ref, watchEffect } from "vue";
import { useStore } from "vuex";
import NewCustomerModal from "@/components/modals/NewCustomerModal.vue";
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { db } from "@/firebase/config";
import Spinner from "@/components/Spinner.vue";
export default {
  components: {
    NewCustomerModal,
    Spinner,
  },
  setup() {
    const store = useStore();
    store.commit("setCurrentRoute", "Customers");

    const showForm = ref(false);

    const customers = ref(null);
    const searchField = ref("firstName");
    const searchValue = ref("");

    const headers = [
      { text: "First Name", value: "firstName", sortable: true },
      { text: "Last Name", value: "lastName", sortable: true },
      { text: "Email", value: "emailAddress", sortable: false },
      { text: "Mobile", value: "mobileNumber", sortable: false },
      { text: "Gender", value: "gender", sortable: true },
      { text: "Status", value: "status", sortable: true },
      { text: "Operations", value: "operations", sortable: false },
    ];

    let colRef = collection(db, "Customers");
    colRef = query(colRef, orderBy("firstName"));

    const unSub = onSnapshot(colRef, (snap) => {
      let results = [];
      snap.docs.forEach((doc) => {
        results.push({ ...doc.data(), id: doc.id });
      });
      customers.value = results;
      console.log(results);
    });

    watchEffect((onInvalidate) => {
      onInvalidate(() => unSub());
    });

    return { showForm, customers, searchField, searchValue, headers };
  },
};
</script>
  
  <style>
</style>