<template>
  <div class="comtainer is-flex is-justify-content-space-between">
    <div>
      <h1 class="is-size-3 title">Dashboard</h1>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
export default {
  setup() {
    const store = useStore();
    store.commit("setCurrentRoute", "Dashboard");
    return {};
  },
};
</script>

<style>
</style>