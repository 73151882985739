<template>
  <div class="app-container">
    <div class="gradient-background"></div>
  </div>
  <div class="columns mx-1 my-6" v-if="user && userProfile">
    <div class="column is-narrow">
      <div class="box">
        <nav-bar />
        <side-nav-bar />
      </div>
    </div>
    <div class="column">
      <div class="box">
        <router-view />
      </div>
    </div>
  </div>
  <div v-if="!user">
    <router-view />
  </div>
</template>


<script>
import { computed } from "vue";
import { useStore } from "vuex";
import SideNavBar from "./components/SideNavBar.vue";
import NavBar from "./components/NavBar.vue";
export default {
  components: { SideNavBar, NavBar },
  name: "App",
  setup() {
    const store = useStore();

    return {
      user: computed(() => store.state.user),
      userProfile: computed(() => store.state.userProfile),
    };
  },
};
</script>

<style>
.gradient-background {
  /* background: linear-gradient(to bottom right, #89cff0, #00509e); */
  /* background: rgb(2, 0, 36); */
  background: rgb(255, 209, 166);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; /* To ensure the background stays behind other content */
}
.app-container {
  position: relative; /* This ensures the fixed gradient background is contained to this element */
}
</style>
